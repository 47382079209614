import {
  getFirestore,
  setDoc,
  doc,
  getDoc,
  deleteDoc,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";

const deleteNamedPlan = async (title) => {
  const result = window.confirm(
    `다음 내용을 삭제하려고 합니다. ${title}\n정말 삭제하시겠습니까?`
  );
  if (result) {
    const db = getFirestore();
    const uid = getAuth().currentUser.uid;
    try {
      await deleteDoc(doc(db, "plans", `${uid}-${title}`));
      const docRef = doc(db, "titles", uid);
      const docSnap = await getDoc(docRef);

      let savedTitles = (docSnap.data() && docSnap.data()["titles"]) ?? [];
      setDoc(doc(db, "titles", `${uid}`), {
        titles: savedTitles.filter((t) => t !== title),
      }).then(() => {
        window.alert(`삭제되었습니다.\n제목: ${title}`);
      });
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  }
};

export { deleteNamedPlan };
