import styled from "styled-components";
import PropTypes from "prop-types";
// import Button from "react-bootstrap/Button";
import { signInWithGoogle, signOutWithGoogle } from "./functions";

import { FcGoogle } from "react-icons/fc";

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  color: gray;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid #357ae8;
  font-size: 16px;
  cursor: pointer;
  gap: 10px;
  &:hover {
    background-color: #357ae8;
  }
`;

const GoogleLogin = ({ currentUser }) => {
  // console.log(currentUser);
  return (
    <>
      {currentUser ? (
        <Button type="submit" onClick={signOutWithGoogle}>
          {" "}
          <FcGoogle />
          <div>로그아웃 하기 </div>
        </Button>
      ) : (
        <Button type="submit" onClick={signInWithGoogle}>
          {" "}
          <FcGoogle />
          <div>구글로 로그인 하기 </div>
        </Button>
      )}
    </>
  );
};

export { GoogleLogin };

GoogleLogin.propTypes = {};

GoogleLogin.defaultProps = {};
