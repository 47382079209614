import { useEffect, useState } from "react";
import styled from "styled-components";
import { PlanContainer } from "./pages/Plan";
import { Typo } from "./components/Typo";
import { GoogleLogin } from "./components/Login/GoogleLogin";

import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, doc, onSnapshot } from "firebase/firestore";
import { app } from "./Firebase"; // firebase init 을 시켜주는 코드로, 빼면 에러가 난다.

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const Bottom = styled.div`
  margin-top: 1rem;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: #f5f5f5;
  border-top: 1px solid #e5e5e5;
`;

const DevLink = styled.a`
  color: #000;
  text-decoration: none;
  font-size: 1rem;
  margin: 0 1rem;
`;

const BitTitle = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
  gap: 5px;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: bold;
  width: 100%;
  padding: 20px;
  background-color: #806751;
  color: #fff;
`;

function App() {
  const auth = getAuth();
  const [currentUser, setCurrentUser] = useState(null);

  const db = getFirestore();
  const uid = currentUser && currentUser.uid;
  const [storedPlanTitles, setSavedPlanTitles] = useState([]);

  useEffect(() => {
    let unsub = () => {};
    if (uid) {
      unsub = onSnapshot(doc(db, "titles", uid), (doc) => {
        const savedTtitles = doc.data() && doc.data()["titles"];
        setSavedPlanTitles(savedTtitles);
      });
    }
    return () => unsub();
  }, [uid]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setCurrentUser(currentUser);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <Container className="App">
      <BitTitle>
        <img src="logo.png" onClick={() => (document.location.href = "/")} />
        <Typo size={"2rem"}>Todo per Two Hour</Typo>
        <Typo size={"1.5rem"}>두 시간당 할 일</Typo>
      </BitTitle>
      <PlanContainer
        currentUser={currentUser}
        storedPlanTitles={storedPlanTitles}
      />
      <GoogleLogin currentUser={currentUser} />
      <Bottom>
        <DevLink href="https://twitter.com/HamsterSyria" target={"_blank"}>
          문의사항 / 개선건의 / 개발자 정보
        </DevLink>
      </Bottom>
    </Container>
  );
}

export default App;
