const savePlanContents = (timeId, contents) => {
  const localStorageKey = `plan`;
  const prevPlan = window.localStorage.getItem(localStorageKey);

  const newPlan = {
    ...JSON.parse(prevPlan),
    [timeId]: contents,
  };

  localStorage.setItem(localStorageKey, JSON.stringify(newPlan));
};

export { savePlanContents };
