import {
  getFirestore,
  setDoc,
  doc,
  getDoc,
  deleteDoc,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";

const loadNamedPlan = async (title) => {
  const result = window.confirm(
    `다음 내용을 불러오려고 합니다: ${title}\n현재 저장하지 않은 내용은 복구할 수 없습니다`
  );
  if (result) {
    const db = getFirestore();
    const uid = getAuth().currentUser.uid;
    try {
      const docRef = doc(db, "plans", `${uid}-${title}`);
      const docSnap = await getDoc(docRef);
      console.log("Current data: ", docSnap.data());

      let savedPlan = docSnap.data() && docSnap.data()["plan"];
      localStorage.setItem("plan", JSON.stringify(savedPlan));
      window.location.reload();
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  }
};

export { loadNamedPlan };
