import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import { clearPlanContents } from "./functions";

const ClearButton = () => {
  return (
    <Button
      variant="secondary"
      type="submit"
      onClick={() => clearPlanContents()}
    >
      {" "}
      입력칸 비우기{" "}
    </Button>
  );
};

export { ClearButton };

ClearButton.propTypes = {};

ClearButton.defaultProps = {};
