import styled from "styled-components";
import PropTypes from "prop-types";
import { TimeBox } from "../../components/TimeBox";
import { Typo } from "../../components/Typo";
import Button from "react-bootstrap/Button";

const Container = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  margin-top: 10px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-bottom: 40px;
`;

const Plan = ({ savedContents, rows }) => {
  // const rows = window.localStorage.getItem("line") || 2;
  return (
    <Container>
      <Typo size={"1.5rem"}>아침 06:00</Typo>
      <TimeBox timeId="06" savedContents={savedContents["06"]} rows={rows} />
      <TimeBox timeId="08" savedContents={savedContents["08"]} rows={rows} />
      <TimeBox timeId="10" savedContents={savedContents["10"]} rows={rows} />
      <Typo size={"1.5rem"}>점심 12:00</Typo>
      <TimeBox timeId="12" savedContents={savedContents["12"]} rows={rows} />
      <TimeBox timeId="14" savedContents={savedContents["14"]} rows={rows} />
      <TimeBox timeId="16" savedContents={savedContents["16"]} rows={rows} />
      <Typo size={"1.5rem"}>저녁 18:00</Typo>
      <TimeBox timeId="18" savedContents={savedContents["18"]} rows={rows} />
      <TimeBox timeId="20" savedContents={savedContents["20"]} rows={rows} />
      <TimeBox timeId="22" savedContents={savedContents["22"]} rows={rows} />
      <Typo size={"1.5rem"}>자정 24:00</Typo>
      <TimeBox timeId="00" savedContents={savedContents["00"]} rows={rows} />
    </Container>
  );
};

export { Plan };

Plan.propTypes = {
  savedContents: PropTypes.object,
};

Plan.defaultProps = {
  savedContents: {
    "06": "",
    "08": "",
    10: "",
    12: "",
    14: "",
    16: "",
    18: "",
    20: "",
    22: "",
    "00": "",
  },
};
