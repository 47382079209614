const clearPlanContents = () => {
  const localStorageKey = `plan`;
  const result = window.confirm(
    "입력칸을 모두 비우시겠습니까?\n저장하지 않은 내용은 복구할 수 없습니다"
  );
  if (result) {
    localStorage.setItem(localStorageKey, JSON.stringify({}));
    window.location.reload();
  }
};

export { clearPlanContents };
