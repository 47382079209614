import { useState, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import "bootstrap/dist/css/bootstrap.min.css";
import Form from "react-bootstrap/Form";

import { savePlanContents } from "./functions";

const TimeBoxStyle = styled.div`
  display: flex;
  flex: 3;
  border: 1px solid ${(props) => (props.isNow ? "green" : "white")};
  border-radius: 8px;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const TimeLabel = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  font-weight: ${(props) => (props.isNow ? "bold" : "normal")};
  color: ${(props) => (props.isNow ? "green" : "black")};
  justify-content: flex-start;
  align-items: center;
`;

const getTimeZero = (time) => {
  if (time < 10) {
    return "0" + time;
  }
  return time;
};

const getTimedelta = (targetTime) => {
  const now = new Date();
  return now - targetTime;
};

const getTargetTime = (timeId) => {
  const now = new Date();
  if (timeId < 2 && now.getHours() > 2) {
    return new Date().setHours(timeId + 24, 0, 0, 0); // 1668513600000
  }
  return new Date().setHours(timeId, 0, 0, 0);
};

const getIsOld = (timedelta) => {
  if (7200000 < timedelta) return true;
  return false;
};

const getIsNow = (timedelta) => {
  // 타겟시간 ~ 타겟시간 + 2시간 : 7200000
  if (timedelta < 0) return false;
  if (0 <= timedelta && timedelta < 7200000) return true;
  if (7200000 < timedelta) return false;
};

const getMinutes = (timedelta) => {
  return Math.floor(120 - ((timedelta / 60000) % 120));
};

const getSeconds = (timedelta) => {
  return Math.floor(60 - ((timedelta / 1000) % 60));
};

const TimeBox = ({ timeId, placeholder, savedContents, currentHour, rows }) => {
  const [contents, setContents] = useState(savedContents);
  const targetTime = getTargetTime(+timeId); // 1668513600000
  const [timedelta, setTimedelta] = useState(getTimedelta(targetTime)); // old < 0  now  7200000 < later
  const [isOld, setIsOld] = useState(getIsOld(timedelta));
  const [isNow, setIsNow] = useState(getIsNow(timedelta));

  const [minutes, setMinutes] = useState(getMinutes(timedelta));
  const [seconds, setSeconds] = useState(getSeconds(timedelta));

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimedelta(getTimedelta(targetTime));
      // console.log("timedelta", timedelta);
      setIsNow(getIsNow(timedelta));
      setIsOld(getIsOld(timedelta));
      if (isNow && !isOld) {
        if (parseInt(seconds) > 0) {
          // setSeconds(parseInt(seconds) - 1);
          setMinutes(getMinutes(timedelta));
          setSeconds(getSeconds(timedelta));
        }
        if (parseInt(seconds) === 0) {
          if (parseInt(minutes) === 0) {
            clearInterval(countdown);
          } else {
            // setMinutes(parseInt(minutes) - 1);
            setMinutes(getMinutes(timedelta));
            setSeconds(59);
          }
        }
      }
    }, 1000);
    return () => clearInterval(countdown);
  }, [timedelta]);

  return (
    <Container>
      <TimeLabel isNow={isNow}>
        <div>~ {getTimeZero(+timeId + 2)}</div>
        {isNow && (
          <div>
            잔여 {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
          </div>
        )}
      </TimeLabel>
      <TimeBoxStyle isNow={isNow} isOld={isOld}>
        <Form.Control
          as="textarea"
          rows={rows}
          value={contents}
          style={{ color: isOld ? "gray" : "black" }}
          maxLength={1000}
          onChange={(e) => {
            setContents(e.target.value);
            savePlanContents(timeId, e.target.value);
          }}
        />
      </TimeBoxStyle>
    </Container>
  );
};

export { TimeBox };

TimeBox.propTypes = {
  timeId: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  savedContents: PropTypes.string,
  currentHour: PropTypes.number,
  rows: PropTypes.number,
};

TimeBox.defaultProps = {
  timeId: "12",
  placeholder: "12:00 ~ 14:00",
  savedContents: "",
  currentHour: new Date().getHours(),
  rows: 1,
};
