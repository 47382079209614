import styled from "styled-components";
import PropTypes from "prop-types";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { loadNamedPlan } from "./functions";

const LoadButton = ({ titles }) => {
  return (
    <DropdownButton id="dropdown-basic" title="불러오기" variant="primary">
      {titles &&
        titles.map((titleKey, i) => (
          <Dropdown.Item
            key={titleKey}
            eventKey={i}
            onClick={() => loadNamedPlan(titleKey)}
          >
            {titleKey}
          </Dropdown.Item>
        ))}
      {/* <Dropdown.Item href="#/action-1">Action</Dropdown.Item> */}
    </DropdownButton>
  );
};

export { LoadButton };

LoadButton.propTypes = {
  titles: PropTypes.array,
};

LoadButton.defaultProps = {
  titles: [],
};
