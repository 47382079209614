import styled from "styled-components";
import PropTypes from "prop-types";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { deleteNamedPlan } from "./functions";

const DeleteButton = ({ titles }) => {
  return (
    <DropdownButton id="dropdown-basic" title="삭제하기" variant="danger">
      {titles &&
        titles.map((titleKey, i) => (
          <Dropdown.Item
            key={titleKey}
            eventKey={i}
            onClick={() => deleteNamedPlan(titleKey)}
          >
            {titleKey}
          </Dropdown.Item>
        ))}
      {/* <Dropdown.Item href="#/action-1">Action</Dropdown.Item> */}
    </DropdownButton>
  );
};

export { DeleteButton };

DeleteButton.propTypes = {
  titles: PropTypes.array,
};

DeleteButton.defaultProps = {
  titles: [],
};
