import PropTypes from "prop-types";
import { useState } from "react";
import Button from "react-bootstrap/Button";
// import { clearPlanContents } from "./functions";
import { AiTwotoneSetting } from "react-icons/ai";

export const SettingButton = ({ setLine }) => {
  return (
    <Button
      variant="secondary"
      type="submit"
      onClick={() => {
        const newLine = window.prompt("입력칸 상하길이 입력: (기본값 2)");
        if (!newLine) {
          return;
        }
        if (Number.isInteger(+newLine)) {
          setLine(+newLine);
        } else {
          alert("숫자를 입력해주세요.");
        }
      }}
    >
      <AiTwotoneSetting />
      설정{" "}
    </Button>
  );
};

SettingButton.propTypes = {};

SettingButton.defaultProps = {};
