import styled from "styled-components";
import PropTypes from "prop-types";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { saveNamedPlan } from "./functions";

const SaveButton = ({ titles }) => {
  const saveNew = () => {
    if (titles.length >= 10) {
      alert("저장할 수 있는 최대갯수는 10개 입니다.");
      return;
    }
    const title = window.prompt("계획 제목을 입력하세요.");
    if (title) {
      saveNamedPlan(title);
    }
  };

  return (
    <DropdownButton id="dropdown-basic" title="저장하기" variant="success">
      <Dropdown.Item
        eventKey="4"
        onClick={() => saveNew()}
        disabled={titles.length >= 10}
      >
        {`새 이름으로 저장 (${10 - titles.length}개 남음)`}
      </Dropdown.Item>
      <Dropdown.Divider />
      {titles &&
        titles.map((titleKey, i) => (
          <Dropdown.Item
            key={titleKey}
            eventKey={i}
            onClick={() => saveNamedPlan(titleKey)}
          >
            {titleKey}
          </Dropdown.Item>
        ))}
      {/* <Dropdown.Item href="#/action-1">Action</Dropdown.Item> */}
    </DropdownButton>
  );
};

export { SaveButton };

SaveButton.propTypes = {
  titles: PropTypes.array,
};

SaveButton.defaultProps = {
  titles: [],
};
