import { getFirestore, setDoc, doc, getDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const currentPlanKey = `plan`;

const saveNamedPlanContent = async (title) => {
  const currentPlan = JSON.parse(window.localStorage.getItem(currentPlanKey));
  // localStorage.setItem(`plan-${title}`, JSON.stringify(currentPlan));

  const db = getFirestore();
  const uid = getAuth().currentUser.uid;
  try {
    await setDoc(doc(db, "plans", `${uid}-${title}`), {
      plan: currentPlan,
      title: title,
      uid: uid,
      updatedAt: new Date().toISOString(),
    });
  } catch (error) {
    console.error("Error adding document: ", error);
  }
};

const registerPlanTitles = async (title) => {
  const db = getFirestore();

  const uid = getAuth().currentUser.uid;

  const docRef = doc(db, "titles", uid);
  const docSnap = await getDoc(docRef);
  let savedTitles = (docSnap.data() && docSnap.data()["titles"]) ?? [];
  const isNewItem = !savedTitles.includes(title);
  let overwrite = false;
  if (!isNewItem) {
    overwrite = window.confirm(
      "이미 같은 이름의 저장된 일정이 있습니다.\n덮어쓰시겠습니까?"
    );
    if (!overwrite) return;
  }
  let newTitles = isNewItem ? [...savedTitles, title] : savedTitles;

  try {
    setDoc(doc(db, "titles", `${uid}`), {
      titles: newTitles,
    }).then(() => {
      window.alert(`저장되었습니다.\n제목: ${title}`);
    });
  } catch (error) {
    console.error("Error adding document: ", error);
  }
};

const saveNamedPlan = async (title) => {
  await registerPlanTitles(title);
  await saveNamedPlanContent(title);
};

export { saveNamedPlan };
