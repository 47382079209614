import styled from "styled-components";
import PropTypes from "prop-types";
import { SaveButton } from "../../components/SaveButton";
import { Plan } from "./Plan";
import { ClearButton } from "../../components/ClearButton/ClearButton";
import { LoadButton } from "../../components/LoadButton/LoadButton";
import { DeleteButton } from "../../components/DeleteButton/DeleteButton";
import { SettingButton } from "../../components/Setting/SettingButton";
import { atom, useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";

const InverseRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

const Container = styled.div`
  width: 350px;
  display: flex;
  gap: 10px;
  margin-top: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 40px;

  width: 1024px;

  @media screen and (max-width: 1023px) {
    width: 768px;
  }

  @media screen and (max-width: 767px) {
    width: 90%;
  }
`;

const lineAtom = atomWithStorage("line", 3);

const PlanContainer = ({ currentUser, storedPlanTitles }) => {
  const savedContents = JSON.parse(window.localStorage.getItem("plan"));
  const [line, setLine] = useAtom(lineAtom);
  return (
    <Container>
      <InverseRow>
        <ClearButton />
        <SettingButton setLine={setLine} />
      </InverseRow>
      <Plan
        savedContents={
          savedContents ?? {
            "06": "",
            "08": "",
            10: "",
            12: "",
            14: "",
            16: "",
            18: "",
            20: "",
            22: "",
            "00": "",
          }
        }
        rows={line}
      />
      {currentUser ? (
        <InverseRow>
          <SaveButton titles={storedPlanTitles} />
          <LoadButton titles={storedPlanTitles} />
          <DeleteButton titles={storedPlanTitles} />
        </InverseRow>
      ) : (
        <>계획 저장 & 불러오기 기능은 로그인이 필요합니다.</>
      )}
    </Container>
  );
};

export { PlanContainer };

PlanContainer.propTypes = {};

PlanContainer.defaultProps = {};
